const Router = {
  MAIN: "/*",
  // HOME: "/",
  LOGIN: "/",
  
  //---------------- Admin ROUTES ---------------------
  ADMIN_DASHBOARD: "/admin_portal",
  ADMIN_USER: "/admin_portal/user",
  ADMIN_MANAGE_CAMPAIGN: "/admin_portal/manage_campaign",
  ADMIN_DID_TFN_NUMBER: "/admin_portal/did_tfn_number",
  ADMIN_REPORT: "/admin_portal/report",
  ADMIN_EXTENSION: "/admin_portal/extension",
  ADMIN_PAYMENT: '/admin_portal/payment',
  ADMIN_CALL_ACTIVE: "/admin_portal/call_actv",
  ADMIN_QUEUE_CALLS:"/admin_portal/queue_call",
  ADMIN_CALL_BLOCK:"/admin_portal/call_block",
  ADMIN_SERVER_STATS: "/admin_portal/server_stats",
  ADMIN_QUEUE: "/admin_portal/queue",
  ADMIN_QUEUE_MEMBER: "/admin_portal/queue_member",
  ADMIN_MINUTES: "/admin_portal/minutes",
  ADMIN_BILLING_MINUTES: "/admin_portal/billing_minutes",
  ADMIN_MDR: "/admin_portal/mdr",
  ADMIN_HISTORY: "/admin_portal/history",
  ADMIN_QUEUE: "/admin_portal/queue",
  ADMIN_AUDIT_LOGS: "/admin_portal/audit_logs",
  ADMIN_RECORDING: "/admin_portal/recording",
  ADMIN_CARRIER: "/admin_portal/carrier",
  ADMIN_VIEW: "/admin_portal/profile",
  ADMIN_LIVE_EXTENSION: "/admin_portal/live_extension",
  ADMIN_IVR_UPLOADS:"/admin_portal/ivr_uploads",
  AdminMOH:"/admin_portal/moh",
  ADMIN_ACL: "/admin_portal/acl",
  ADMIN_ACL_HISTORY: "/admin_portal/acl_history",
  ADMIN_PERMISSIONS_ACCESS:"/admin_portal/permissions",
  ADMIN_TFN_ASSISTANT:"/admin_portal/assistant",
  ADMIN_INVOICE: "/admin_portal/invoice",
  ADMIN_PRODUCT: "/admin_portal/product",

   //---------------- Reseller ROUTES ---------------------
   RESELLER_DASHBOARD: "/reseller_portal",
   RESELLER_USER: "/reseller_portal/user",
   RESELLER_MANAGE_CAMPAIGN: "/reseller_portal/manage_campaign",
   RESELLER_DID_TFN_NUMBER: "/reseller_portal/did_tfn_number",
   RESELLER_REPORT: "/reseller_portal/report",
   RESELLER_EXTENSION: "/reseller_portal/extension",
   RESELLER_CALL_ACTIVE: "/reseller_portal/call_actv",
   RESELLER_QUEUE_CALLS:"/reseller_portal/queue_call",
   RESELLER_CALL_BLOCK:"/reseller_portal/call_block",
   RESELLER_SERVER_STATS: "/reseller_portal/server_stats",
   RESELLER_QUEUE: "/reseller_portal/queue",
   RESELLER_QUEUE_MEMBER: "/reseller_portal/queue_member",
   RESELLER_MINUTES: "/reseller_portal/minutes",
   RESELLER_BILLING_MINUTES: "/reseller_portal/billing_minutes",
   RESELLER_MDR: "/reseller_portal/mdr",
   RESELLER_HISTORY: "/reseller_portal/history",
   RESELLER_QUEUE: "/reseller_portal/queue",
   RESELLER_AUDIT_LOGS: "/reseller_portal/audit_logs",
   RESELLER_RECORDING: "/reseller_portal/recording",
   RESELLER_CARRIER: "/reseller_portal/carrier",
   RESELLER_VIEW: "/reseller_portal/profile",
   RESELLER_LIVE_EXTENSION: "/reseller_portal/live_extension",
   RESELLER_IVR_UPLOADS:"/reseller_portal/ivr_uploads",
   RESELLER_MOH:"/reseller_portal/reseller_moh",
   RESELLER_ACL: "/reseller_portal/reseller_acl",
   RESELLER_PERMISSIONS_ACCESS:"/reseller_portal/permissions",
   RESELLER_TFN_ASSISTANT:"/reseller_portal/reseller_assistant",

  //------------------Manage Routes------------------
  MANAGE_DASHBOARD: "/manage_portal",
  MANAGE_EXTENSIONS: "/manage_portal/extensions",
  MANAGE_CALL_CENTER: "/manage_portal/call_center",
  MANAGE_OPERATOR_PANEL: "/manage_portal/operator_panel",
  MANAGE_DESTINATION: "/manage_portal/destination",
  MANAGE_REPORT: "/manage_portal/report",
  MANAGE_ACCOUNT: "/manage_portal/account",
  MANAGE_CALL_BLOCK: "/manage_portal/call_block",
  MANAGE_CALL_ACTIVE: "/manage_portal/active_calls",
  MANAGE_BILLING: "/manage_portal/billing_history",
  MANAGE_RECORDING: "/manage_portal/recording",
  MANAGE_MOH: "/manage_portal/moh",
  MANAGE_QUEUE:"/manage_portal/queue",
  MANAGE_QUEUE_MEMBER:"/manage_portal/queue_member",
  MANAGE_LIVE_EXTENSION: "/manage_portal/live_extension",
  MANAGE_AUDIT_LOGS:"/manage_portal/audit_logs",
};

export default Router;
