export const GET_ADMIN_RECORDING_REQUEST = "GET_ADMIN_RECORDING_REQUEST";
export const GET_ADMIN_RECORDING_SUCCESS = "GET_ADMIN_RECORDING_SUCCESS";
export const GET_ADMIN_RECORDING_FAIL = "GET_ADMIN_RECORDING_FAIL";

export const CREATE_ADMIN_RECORDING_REQUEST = "CREATE_ADMIN_RECORDING_REQUEST";
export const CREATE_ADMIN_RECORDING_SUCCESS = "CREATE_ADMIN_RECORDING_SUCCESS";
export const CREATE_ADMIN_RECORDING_FAIL = "CREATE_ADMIN_RECORDING_FAIL";

export const UPDATE_ADMIN_RECORDING_REQUEST = "UPDATE_ADMIN_RECORDING_REQUEST";
export const UPDATE_ADMIN_RECORDING_SUCCESS = "UPDATE_ADMIN_RECORDING_SUCCESS";
export const UPDATE_ADMIN_RECORDING_FAIL = "UPDATE_ADMIN_RECORDING_FAIL";