export const GET_ADMIN_MOH_REQUEST = "GET_ADMIN_MOH_REQUEST";
export const GET_ADMIN_MOH_SUCCESS = "GET_ADMIN_MOH_SUCCESS";
export const GET_ADMIN_MOH_FAIL = "GET_ADMIN_MOH_FAIL";

export const CREATE_ADMIN_MOH_REQUEST = "CREATE_ADMIN_MOH_REQUEST";
export const CREATE_ADMIN_MOH_SUCCESS = "CREATE_ADMIN_MOH_SUCCESS";
export const CREATE_ADMIN_MOH_FAIL = "CREATE_ADMIN_MOH_FAIL";

export const UPDATE_ADMIN_MOH_REQUEST = "UPDATE_ADMIN_MOH_REQUEST";
export const UPDATE_ADMIN_MOH_SUCCESS = "UPDATE_ADMIN_MOH_SUCCESS";
export const UPDATE_ADMIN_MOH_FAIL = "UPDATE_ADMIN_MOH_FAIL";