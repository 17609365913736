export const GET_ADMIN_HISTORY_REQUEST = "GET_ADMIN_HISTORY_REQUEST";
export const GET_ADMIN_HISTORY_SUCCESS = "GET_ADMIN_HISTORY_SUCCESS";
export const GET_ADMIN_HISTORY_FAIL = "GET_ADMIN_HISTORY_FAIL";

export const GET_ADMIN_ADD_MINUTE_REQUEST = "GET_ADMIN_ADD_MINUTE_REQUEST";
export const GET_ADMIN_ADD_MINUTE_SUCCESS = "GET_ADMIN_ADD_MINUTE_SUCCESS";
export const GET_ADMIN_ADD_MINUTE_FAIL = "GET_ADMIN_ADD_MINUTE_FAIL";

export const POST_ADMIN_ADD_MINUTE_REQUEST = "POST_ADMIN_ADD_MINUTE_REQUEST";
export const POST_ADMIN_ADD_MINUTE_SUCCESS = "POST_ADMIN_ADD_MINUTE_SUCCESS";
export const POST_ADMIN_ADD_MINUTE_FAIL = "POST_ADMIN_ADD_MINUTE_FAIL";