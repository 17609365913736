export const GET_ADMIN_USERS_REQUEST = "GET_ADMIN_USERS_REQUEST";
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS";
export const GET_ADMIN_USERS_FAIL = "GET_ADMIN_USERS_FAIL";

export const GET_ADMIN_RESELLERS_REQUEST = "GET_ADMIN_RESELLERS_REQUEST";
export const GET_ADMIN_RESELLERS_SUCCESS = "GET_ADMIN_RESELLERS_SUCCESS";
export const GET_ADMIN_RESELLERS_FAIL = "GET_ADMIN_RESELLERS_FAIL";

export const GET_RESELLER_USERS_REQUEST = "GET_RESELLER_USERS_REQUEST";
export const GET_RESELLER_USERS_SUCCESS = "GET_RESELLER_USERS_SUCCESS";
export const GET_RESELLER_USERS_FAIL = "GET_RESELLER_USERS_FAIL";

export const GET_RESELLER_REMAINING_MINUTES_REQUEST = "GET_RESELLER_REMAINING_MINUTES_REQUEST";
export const GET_RESELLER_REMAINING_MINUTES_SUCCESS = "GET_RESELLER_REMAINING_MINUTES_SUCCESS";
export const GET_RESELLER_REMAINING_MINUTES_FAIL = "GET_RESELLER_REMAINING_MINUTES_FAIL";