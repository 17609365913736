export const GET_EXTENSION_REQUEST = "GET_EXTENSION_REQUEST";
export const GET_EXTENSION_SUCCESS = "GET_EXTENSION_SUCCESS";
export const GET_EXTENSION_FAIL = "GET_EXTENSION_FAIL";

export const CREATE_EXTENSION_REQUEST = "CREATE_EXTENSION_REQUEST";
export const CREATE_EXTENSION_SUCCESS = "CREATE_EXTENSION_SUCCESS";
export const CREATE_EXTENSION_FAIL = "CREATE_EXTENSION_FAIL";

export const UPDATE_EXTENSION_REQUEST = "UPDATE_EXTENSION_REQUEST";
export const UPDATE_EXTENSION_SUCCESS = "UPDATE_EXTENSION_SUCCESS";
export const UPDATE_EXTENSION_FAIL = "UPDATE_EXTENSION_FAIL";

export const DELETE_EXTENSION_REQUEST = "DELETE_EXTENSION_REQUEST";
export const DELETE_EXTENSION_SUCCESS = "DELETE_EXTENSION_SUCCESS";
export const DELETE_EXTENSION_FAIL = "DELETE_EXTENSION_FAIL";
