export const GET_ADMIN_QUEUE_REQUEST = "GET_ADMIN_QUEUE_REQUEST";
export const GET_ADMIN_QUEUE_SUCCESS = "GET_ADMIN_QUEUE_SUCCESS";
export const GET_ADMIN_QUEUE_FAIL = "GET_ADMIN_QUEUE_FAIL";

export const CREATE_ADMIN_QUEUE_REQUEST = "CREATE_ADMIN_QUEUE_REQUEST";
export const CREATE_ADMIN_QUEUE_SUCCESS = "CREATE_ADMIN_QUEUE_SUCCESS";
export const CREATE_ADMIN_QUEUE_FAIL = "CREATE_ADMIN_QUEUE_FAIL";

export const UPDATE_ADMIN_QUEUE_REQUEST = "UPDATE_ADMIN_QUEUE_REQUEST";
export const UPDATE_ADMIN_QUEUE_SUCCESS = "UPDATE_ADMIN_QUEUE_SUCCESS";
export const UPDATE_ADMIN_QUEUE_FAIL = "UPDATE_ADMIN_QUEUE_FAIL";

export const GET_ADMIN_QUEUE_MEMBER_REQUEST = "GET_ADMIN_QUEUE_MEMBER_REQUEST";
export const GET_ADMIN_QUEUE_MEMBER_SUCCESS = "GET_ADMIN_QUEUE_MEMBER_SUCCESS";
export const GET_ADMIN_QUEUE_MEMBER_FAIL = "GET_ADMIN_QUEUE_MEMBER_FAIL";

export const CREATE_ADMIN_QUEUE_MEMBER_REQUEST = "CREATE_ADMIN_QUEUE_MEMBER_REQUEST";
export const CREATE_ADMIN_QUEUE_MEMBER_SUCCESS = "CREATE_ADMIN_QUEUE_MEMBER_SUCCESS";
export const CREATE_ADMIN_QUEUE_MEMBER_FAIL = "CREATE_ADMIN_QUEUE_MEMBER_FAIL";

export const DELETE_ADMIN_QUEUE_MEMBER_REQUEST = "DELETE_ADMIN_QUEUE_MEMBER_REQUEST";
export const DELETE_ADMIN_QUEUE_MEMBER_SUCCESS = "DELETE_ADMIN_QUEUE_MEMBER_SUCCESS";
export const DELETE_ADMIN_QUEUE_MEMBER_FAIL = "DELETE_ADMIN_QUEUE_MEMBER_FAIL";