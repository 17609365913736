export const GET_ADMIN_CALL_BLOCK_REQUEST = "GET_ADMIN_CALL_BLOCK_REQUEST";
export const GET_ADMIN_CALL_BLOCK_SUCCESS = "GET_ADMIN_CALL_BLOCK_SUCCESS";
export const GET_ADMIN_CALL_BLOCK_FAIL = "GET_ADMIN_CALL_BLOCK_FAIL";

export const CREATE_ADMIN_CALL_BLOCK_REQUEST = "CREATE_ADMIN_CALL_BLOCK_REQUEST";
export const CREATE_ADMIN_CALL_BLOCK_SUCCESS = "CREATE_ADMIN_CALL_BLOCK_SUCCESS";
export const CREATE_ADMIN_CALL_BLOCK_FAIL = "CREATE_ADMIN_CALL_BLOCK_FAIL";

export const UPDATE_ADMIN_CALL_BLOCK_REQUEST = "UPDATE_ADMIN_CALL_BLOCK_REQUEST";
export const UPDATE_ADMIN_CALL_BLOCK_SUCCESS = "UPDATE_ADMIN_CALL_BLOCK_SUCCESS";
export const UPDATE_ADMIN_CALL_BLOCK_FAIL = "UPDATE_ADMIN_CALL_BLOCK_FAIL";

export const DELETE_ADMIN_CALL_BLOCK_REQUEST = "DELETE_ADMIN_CALL_BLOCK_REQUEST";
export const DELETE_ADMIN_CALL_BLOCK_SUCCESS = "DELETE_ADMIN_CALL_BLOCK_SUCCESS";
export const DELETE_ADMIN_CALL_BLOCK_FAIL = "DELETE_ADMIN_CALL_BLOCK_FAIL";