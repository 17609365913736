export const GET_MANAGE_CALL_BLOCK_REQUEST = "GET_MANAGE_CALL_BLOCK_REQUEST";
export const GET_MANAGE_CALL_BLOCK_SUCCESS = "GET_MANAGE_CALL_BLOCK_SUCCESS";
export const GET_MANAGE_CALL_BLOCK_FAIL = "GET_MANAGE_CALL_BLOCK_FAIL";

export const CREATE_MANAGE_CALL_BLOCK_REQUEST = "CREATE_MANAGE_CALL_BLOCK_REQUEST";
export const CREATE_MANAGE_CALL_BLOCK_SUCCESS = "CREATE_MANAGE_CALL_BLOCK_SUCCESS";
export const CREATE_MANAGE_CALL_BLOCK_FAIL = "CREATE_MANAGE_CALL_BLOCK_FAIL";

export const UPDATE_MANAGE_CALL_BLOCK_REQUEST = "UPDATE_MANAGE_CALL_BLOCK_REQUEST";
export const UPDATE_MANAGE_CALL_BLOCK_SUCCESS = "UPDATE_MANAGE_CALL_BLOCK_SUCCESS";
export const UPDATE_MANAGE_CALL_BLOCK_FAIL = "UPDATE_MANAGE_CALL_BLOCK_FAIL";

export const DELETE_MANAGE_CALL_BLOCK_REQUEST = "DELETE_MANAGE_CALL_BLOCK_REQUEST";
export const DELETE_MANAGE_CALL_BLOCK_SUCCESS = "DELETE_MANAGE_CALL_BLOCK_SUCCESS";
export const DELETE_MANAGE_CALL_BLOCK_FAIL = "DELETE_MANAGE_CALL_BLOCK_FAIL";