export const GET_ADMIN_ACL_REQUEST = "GET_ADMIN_ACL_REQUEST";
export const GET_ADMIN_ACL_SUCCESS = "GET_ADMIN_ACL_SUCCESS";
export const GET_ADMIN_ACL_FAIL = "GET_ADMIN_ACL_FAIL";

export const CREATE_ADMIN_ACL_REQUEST = "CREATE_ADMIN_ACL_REQUEST";
export const CREATE_ADMIN_ACL_SUCCESS = "CREATE_ADMIN_ACL_SUCCESS";
export const CREATE_ADMIN_ACL_FAIL = "CREATE_ADMIN_ACL_FAIL";

export const UPDATE_ADMIN_ACL_REQUEST = "UPDATE_ADMIN_ACL_REQUEST";
export const UPDATE_ADMIN_ACL_SUCCESS = "UPDATE_ADMIN_ACL_SUCCESS";
export const UPDATE_ADMIN_ACL_FAIL = "UPDATE_ADMIN_ACL_FAIL";

export const DELETE_ADMIN_ACL_REQUEST = "DELETE_ADMIN_ACL_REQUEST";
export const DELETE_ADMIN_ACL_SUCCESS = "DELETE_ADMIN_ACL_SUCCESS";
export const DELETE_ADMIN_ACL_FAIL = "DELETE_ADMIN_ACL_FAIL";