export const RESELLER_DASHBOARD_CHART_REQUEST = "RESELLER_DASHBOARD_CHART_REQUEST";
export const RESELLER_DASHBOARD_CHART_SUCCESS = "RESELLER_DASHBOARD_CHART_SUCCESS";
export const RESELLER_DASHBOARD_CHART_FAIL = "RESELLER_DASHBOARD_CHART_FAIL";

export const RESELLER_DASHBOARD_BILLING_CHART_REQUEST = "RESELLER_DASHBOARD_BILLING_CHART_REQUEST";
export const RESELLER_DASHBOARD_BILLING_CHART_SUCCESS = "RESELLER_DASHBOARD_BILLING_CHART_SUCCESS";
export const RESELLER_DASHBOARD_BILLING_CHART_FAIL = "RESELLER_DASHBOARD_BILLING_CHART_FAIL";

export const RESELLER_DASHBOARD_LINE_CHART_REQUEST = "RESELLER_DASHBOARD_LINE_CHART_REQUEST";
export const RESELLER_DASHBOARD_LINE_CHART_SUCCESS = "RESELLER_DASHBOARD_LINE_CHART_SUCCESS";
export const RESELLER_DASHBOARD_LINE_CHART_FAIL = "RESELLER_DASHBOARD_LINE_CHART_FAIL";