export const ALL_DASHBOARD_CHART_REQUEST = "ALL_DASHBOARD_CHART_REQUEST";
export const ALL_DASHBOARD_CHART_SUCCESS = "ALL_DASHBOARD_CHART_SUCCESS";
export const ALL_DASHBOARD_CHART_FAIL = "ALL_DASHBOARD_CHART_FAIL";

export const ALL_DASHBOARD_BILLING_CHART_REQUEST = "ALL_DASHBOARD_BILLING_CHART_REQUEST";
export const ALL_DASHBOARD_BILLING_CHART_SUCCESS = "ALL_DASHBOARD_BILLING_CHART_SUCCESS";
export const ALL_DASHBOARD_BILLING_CHART_FAIL = "ALL_DASHBOARD_BILLING_CHART_FAIL";

export const ALL_DASHBOARD_LINE_CHART_REQUEST = "ALL_DASHBOARD_LINE_CHART_REQUEST";
export const ALL_DASHBOARD_LINE_CHART_SUCCESS = "ALL_DASHBOARD_LINE_CHART_SUCCESS";
export const ALL_DASHBOARD_LINE_CHART_FAIL = "ALL_DASHBOARD_LINE_CHART_FAIL";