export const GET_MANAGE_MOH_REQUEST = "GET_MANAGE_MOH_REQUEST";
export const GET_MANAGE_MOH_SUCCESS = "GET_MANAGE_MOH_SUCCESS";
export const GET_MANAGE_MOH_FAIL = "GET_MANAGE_MOH_FAIL";

export const CREATE_MANAGE_MOH_REQUEST = "CREATE_MANAGE_MOH_REQUEST";
export const CREATE_MANAGE_MOH_SUCCESS = "CREATE_MANAGE_MOH_SUCCESS";
export const CREATE_MANAGE_MOH_FAIL = "CREATE_MANAGE_MOH_FAIL";

export const UPDATE_MANAGE_MOH_REQUEST = "UPDATE_MANAGE_MOH_REQUEST";
export const UPDATE_MANAGE_MOH_SUCCESS = "UPDATE_MANAGE_MOH_SUCCESS";
export const UPDATE_MANAGE_MOH_FAIL = "UPDATE_MANAGE_MOH_FAIL";