export const GET_ADMIN_INVOICE_REQUEST = "GET_ADMIN_INVOICE_REQUEST";
export const GET_ADMIN_INVOICE_SUCCESS = "GET_ADMIN_INVOICE_SUCCESS";
export const GET_ADMIN_INVOICE_FAIL = "GET_ADMIN_INVOICE_FAIL";

export const CREATE_ADMIN_INVOICE_REQUEST = "CREATE_ADMIN_INVOICE_REQUEST";
export const CREATE_ADMIN_INVOICE_SUCCESS = "CREATE_ADMIN_INVOICE_SUCCESS";
export const CREATE_ADMIN_INVOICE_FAIL = "CREATE_ADMIN_INVOICE_FAIL";

export const UPDATE_ADMIN_INVOICE_REQUEST = "UPDATE_ADMIN_INVOICE_REQUEST";
export const UPDATE_ADMIN_INVOICE_SUCCESS = "UPDATE_ADMIN_INVOICE_SUCCESS";
export const UPDATE_ADMIN_INVOICE_FAIL = "UPDATE_ADMIN_INVOICE_FAIL";