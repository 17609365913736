import { Box } from '@mui/material'
import React from 'react'

function OperatorPanel() {
  return (
  <>
  <Box sx={{position:"relative",top:"8rem",}}><h1>Operator Panel</h1></Box></>
    
  )
}

export default OperatorPanel