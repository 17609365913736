export const GET_MANAGE_DID_REQUEST = "GET_MANAGE_DID_REQUEST";
export const GET_MANAGE_DID_SUCCESS = "GET_MANAGE_DID_SUCCESS";
export const GET_MANAGE_DID_FAIL = "GET_MANAGE_DID_FAIL";

export const CREATE_MANAGE_DESTINATION_REQUEST = "CREATE_MANAGE_DESTINATION_REQUEST";
export const CREATE_MANAGE_DESTINATION_SUCCESS = "CREATE_MANAGE_DESTINATION_SUCCESS";
export const CREATE_MANAGE_DESTINATION_FAIL = "CREATE_MANAGE_DESTINATION_FAIL";

export const UPDATE_MANAGE_DESTINATION_REQUEST = "UPDATE_MANAGE_DESTINATION_REQUEST";
export const UPDATE_MANAGE_DESTINATION_SUCCESS = "UPDATE_MANAGE_DESTINATION_SUCCESS";
export const UPDATE_MANAGE_DESTINATION_FAIL = "UPDATE_MANAGE_DESTINATION_FAIL";