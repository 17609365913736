export const GET_RESELLER_DID_REQUEST = "GET_RESELLER_DID_REQUEST";
export const GET_RESELLER_DID_SUCCESS = "GET_RESELLER_DID_SUCCESS";
export const GET_RESELLER_DID_FAIL = "GET_RESELLER_DID_FAIL";

export const CREATE_RESELLER_DESTINATION_REQUEST = "CREATE_RESELLER_DESTINATION_REQUEST";
export const CREATE_RESELLER_DESTINATION_SUCCESS = "CREATE_RESELLER_DESTINATION_SUCCESS";
export const CREATE_RESELLER_DESTINATION_FAIL = "CREATE_RESELLER_DESTINATION_FAIL";

export const UPDATE_RESELLER_DESTINATION_REQUEST = "UPDATE_RESELLER_DESTINATION_REQUEST";
export const UPDATE_RESELLER_DESTINATION_SUCCESS = "UPDATE_RESELLER_DESTINATION_SUCCESS";
export const UPDATE_RESELLER_DESTINATION_FAIL = "UPDATE_RESELLER_DESTINATION_FAIL";