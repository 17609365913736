export const GET_MANAGE_QUEUE_REQUEST = "GET_MANAGE_QUEUE_REQUEST";
export const GET_MANAGE_QUEUE_SUCCESS = "GET_MANAGE_QUEUE_SUCCESS";
export const GET_MANAGE_QUEUE_FAIL = "GET_MANAGE_QUEUE_FAIL";

export const CREATE_MANAGE_QUEUE_REQUEST = "CREATE_MANAGE_QUEUE_REQUEST";
export const CREATE_MANAGE_QUEUE_SUCCESS = "CREATE_MANAGE_QUEUE_SUCCESS";
export const CREATE_MANAGE_QUEUE_FAIL = "CREATE_MANAGE_QUEUE_FAIL";

export const UPDATE_MANAGE_QUEUE_REQUEST = "UPDATE_MANAGE_QUEUE_REQUEST";
export const UPDATE_MANAGE_QUEUE_SUCCESS = "UPDATE_MANAGE_QUEUE_SUCCESS";
export const UPDATE_MANAGE_QUEUE_FAIL = "UPDATE_MANAGE_QUEUE_FAIL";

export const GET_MANAGE_QUEUE_MEMBER_REQUEST = "GET_MANAGE_QUEUE_MEMBER_REQUEST";
export const GET_MANAGE_QUEUE_MEMBER_SUCCESS = "GET_MANAGE_QUEUE_MEMBER_SUCCESS";
export const GET_MANAGE_QUEUE_MEMBER_FAIL = "GET_MANAGE_QUEUE_MEMBER_FAIL";

export const CREATE_MANAGE_QUEUE_MEMBER_REQUEST = "CREATE_MANAGE_QUEUE_MEMBER_REQUEST";
export const CREATE_MANAGE_QUEUE_MEMBER_SUCCESS = "CREATE_MANAGE_QUEUE_MEMBER_SUCCESS";
export const CREATE_MANAGE_QUEUE_MEMBER_FAIL = "CREATE_MANAGE_QUEUE_MEMBER_FAIL";

export const DELETE_MANAGE_QUEUE_MEMBER_REQUEST = "DELETE_MANAGE_QUEUE_MEMBER_REQUEST";
export const DELETE_MANAGE_QUEUE_MEMBER_SUCCESS = "DELETE_MANAGE_QUEUE_MEMBER_SUCCESS";
export const DELETE_MANAGE_QUEUE_MEMBER_FAIL = "DELETE_MANAGE_QUEUE_MEMBER_FAIL";